import React from 'react';
import './App.css';

import {TasksApi} from './modules/TasksApi';

class App extends React.Component {
	api;

	constructor(){
		super();

		this.state = {tasks: []}
	}

	componentDidMount(){
		new TasksApi().then( (result) => {
			this.api = result;
			this.api.getTasks().then( (result) => {
				this.setState( {tasks: result.map( (task) => {
					return this.buildTaskItem(task);
				} )} );
			} );
		} );
	}

	addTask(event){
		event.preventDefault();

		this.api.addTask(event.target.elements.task.value)
			.then( (result) => {
				let temp = this.state.tasks;

				temp.push(
					this.buildTaskItem(result)
				);

				this.setState({tasks: temp});
			} );

		event.target.elements.task.value = '';
	}

	deleteTask(event){
		this.api.deleteTask(event.target.value)
			.then( (result) => {
				let temp = this.state.tasks;

				temp = temp.filter( (task) => {
					return task.key !== result;
				} );

				this.setState({tasks: temp})
			} );
	}

	buildTaskItem(task){
		return <li key={task.id}>{task.description} <input type="checkbox" value={task.id} onChange={this.deleteTask.bind(this)}/></li>
	}

	render(){
		return (
			<div>
				<h1>sos todo</h1>

				<form onSubmit={this.addTask.bind(this)}>
					<input name="task" type="text"/>
				</form>
	
				<ul>
					{this.state.tasks}
				</ul>
			</div>
		);
	}
}

export default App;
