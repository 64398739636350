class TasksApi{
	db;

	constructor(){
		return new Promise( (resolve, reject) => {
			let request = indexedDB.open('sos-tasks', 1);
			request.onerror = (event) => {
				reject(event);
			};
			request.onsuccess = (event) => {
				this.db = event.target.result;
				resolve(this, event);
			};
			request.onupgradeneeded = (event) => {
				const db = event.target.result;

				db.createObjectStore('tasks', {keyPath: 'id', autoIncrement: true});
			}
		} );
	}

	getTasks(){
		return new Promise( (resolve, reject) => {
			let transaction = this.db.transaction(['tasks'], 'readonly');
			transaction.onerror = (event) => {
				reject(event);
			};

			const store = transaction.objectStore('tasks');

			let request = store.getAll();
			request.onsuccess = (event) => {
				resolve(event.target.result, event);
			};
		} );
	}

	addTask(description){
		return new Promise( (resolve, reject) => {
			let transaction = this.db.transaction(['tasks'], 'readwrite');
			transaction.onerror = (event) => {
				reject(event);
			};
			
			const store = transaction.objectStore('tasks');

			let request = store.add({description: description});
			request.onsuccess = (event) => {
				resolve( {
					id: event.target.result,
					description: description
				}, event );
			};
		} );
	}
	
	deleteTask(id){
		return new Promise( (resolve, reject) => {
			let transaction = this.db.transaction(['tasks'], 'readwrite');
			transaction.onerror = (event) => {
				reject(event);
			};

			let store = transaction.objectStore('tasks');

			let request = store.delete(parseInt(id));
			request.onsuccess = (event) => {
				resolve(id, event);
			};
		} );
	}
}

export {TasksApi};